import { CloudWatchLogsClientResolvedConfig, ServiceInputTypes, ServiceOutputTypes } from "../CloudWatchLogsClient";
import { GetLogGroupFieldsRequest, GetLogGroupFieldsResponse } from "../models/models_0";
import {
  deserializeAws_json1_1GetLogGroupFieldsCommand,
  serializeAws_json1_1GetLogGroupFieldsCommand,
} from "../protocols/Aws_json1_1";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { HttpRequest as __HttpRequest, HttpResponse as __HttpResponse } from "@aws-sdk/protocol-http";
import { Command as $Command } from "@aws-sdk/smithy-client";
import {
  FinalizeHandlerArguments,
  Handler,
  HandlerExecutionContext,
  MiddlewareStack,
  HttpHandlerOptions as __HttpHandlerOptions,
  MetadataBearer as __MetadataBearer,
  SerdeContext as __SerdeContext,
} from "@aws-sdk/types";

export type GetLogGroupFieldsCommandInput = GetLogGroupFieldsRequest;
export type GetLogGroupFieldsCommandOutput = GetLogGroupFieldsResponse & __MetadataBearer;

/**
 * <p>Returns a list of the fields that are included in log events in the specified log group, along with the percentage of log events
 *     that contain each field. The search is limited to a time period that you specify.</p>
 *          <p>In the results, fields that start with @ are fields generated by CloudWatch Logs. For
 *       example, <code>@timestamp</code> is the timestamp of each log event. For more information about the fields that are
 *       generated by CloudWatch logs, see
 *       <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/CWL_AnalyzeLogData-discoverable-fields.html">Supported Logs and Discovered Fields</a>.</p>
 *          <p>The response results are sorted by the frequency percentage, starting
 *     with the highest percentage.</p>
 */
export class GetLogGroupFieldsCommand extends $Command<
  GetLogGroupFieldsCommandInput,
  GetLogGroupFieldsCommandOutput,
  CloudWatchLogsClientResolvedConfig
> {
  // Start section: command_properties
  // End section: command_properties

  constructor(readonly input: GetLogGroupFieldsCommandInput) {
    // Start section: command_constructor
    super();
    // End section: command_constructor
  }

  /**
   * @internal
   */
  resolveMiddleware(
    clientStack: MiddlewareStack<ServiceInputTypes, ServiceOutputTypes>,
    configuration: CloudWatchLogsClientResolvedConfig,
    options?: __HttpHandlerOptions
  ): Handler<GetLogGroupFieldsCommandInput, GetLogGroupFieldsCommandOutput> {
    this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));

    const stack = clientStack.concat(this.middlewareStack);

    const { logger } = configuration;
    const clientName = "CloudWatchLogsClient";
    const commandName = "GetLogGroupFieldsCommand";
    const handlerExecutionContext: HandlerExecutionContext = {
      logger,
      clientName,
      commandName,
      inputFilterSensitiveLog: GetLogGroupFieldsRequest.filterSensitiveLog,
      outputFilterSensitiveLog: GetLogGroupFieldsResponse.filterSensitiveLog,
    };
    const { requestHandler } = configuration;
    return stack.resolve(
      (request: FinalizeHandlerArguments<any>) =>
        requestHandler.handle(request.request as __HttpRequest, options || {}),
      handlerExecutionContext
    );
  }

  private serialize(input: GetLogGroupFieldsCommandInput, context: __SerdeContext): Promise<__HttpRequest> {
    return serializeAws_json1_1GetLogGroupFieldsCommand(input, context);
  }

  private deserialize(output: __HttpResponse, context: __SerdeContext): Promise<GetLogGroupFieldsCommandOutput> {
    return deserializeAws_json1_1GetLogGroupFieldsCommand(output, context);
  }

  // Start section: command_body_extra
  // End section: command_body_extra
}
